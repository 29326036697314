//Background
$bg_main: #0e1621;
$bg_dark: #212a37;
$bg_form: #353e4b;
$gradient_text: linear-gradient(90deg, #a770ef 0%, #cf8bf3 50%, #fdb99b 100%);
$gradient_button: linear-gradient(88.69deg, #c216ff 0.65%, #1677ff 151.38%);
$gradient_button_disabled: linear-gradient(88.69deg, rgba(194, 22, 255, 0.6) 0.65%, rgba(22, 119, 255, 0.6) 151.38%);
$gradient_angular: conic-gradient(from 180deg at 50% 50%, #006afb 0deg, #5ca1fe 360deg, #5ca1fe 360deg);
.gradient_text {
  background: $gradient_text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

//Color
$primary: #007fff;
$secondary: #343d4a;
$secondary_disabled: #3e4754;
$stroke: #717493;
$stroke2: #6e6976;
$stroke_disabled: #797481;
$success: #3ea972;
$text: #eaeaea;
$text2: #a6aaaf;
$black: #000000;
$white: #ffffff;
$white_05: #ffffff50;
$white_06: #ffffff60;
$white_07: #ffffff70;
$white_09: #ffffff90;
$white_disable: rgba(255, 255, 255, 0.6);

//Size
$border_radius: 12px;

//Transition
$transition: 0.3s;

// home blur top
.home_blur {
  width: 150px;
    height: 150px;
    position: absolute;
    left: 250px;
    top: 300px;
    background: linear-gradient(-1deg, #282e7b -42%, #5e43d6 -13%, #5e43d6 -72%);
    filter: blur(180px);
}

//Blur
.blur_purple {
  width: 670px;
  height: 670px;

  position: absolute;
  left: -284px;
  top: 452px;

  background: linear-gradient(90deg, #282e7b 0%, #5e43d6 100%, #5e43d6 100%);
  filter: blur(225px);
}
.blur_blue {
  width: 973px;
  height: 658px;

  position: absolute;
  left: 1228px;
  top: 0px;

  background: linear-gradient(
    90deg,
    rgba(0, 82, 212, 0.5) 0%,
    rgba(67, 100, 247, 0.5) 50%,
    rgba(111, 177, 252, 0.5) 100%
  );
  filter: blur(205px);
  transform: rotate(-22.98deg);
}
.blur_red {
  width: 670px;
  height: 670px;

  position: absolute;
  left: 712px;
  top: 424px;

  background: linear-gradient(90deg, #973939 0%, #5e43d6 100%, #4e2aef 100%);
  filter: blur(225px);
}
.blur_red_top {
  @extend .blur_red;
  left: 205px;
  top: -433px;
}

//Text
.text_xs {
  font-weight: 400;
  font-size: 14px;
  color: $white;
}
.text_s {
  font-weight: 400;
  font-size: 16px;
  color: $white;
}
.text {
  font-weight: 500;
  font-size: 18px;
  color: $white;
}
.text_l {
  font-weight: 500;
  font-size: 20px;
  color: $white;
}
.title_sm {
  font-weight: 600;
  font-size: 24px;
  color: $white;
}
.title {
  font-weight: 600;
  font-size: 32px;
  color: $white;
}
.title_l {
  font-weight: 600;
  font-size: 40px;
  color: $white;
}
.title_xl {
  font-weight: 700;
  font-size: 64px;
  color: $white;
}

//Position
.display_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin flex {
  display: flex;
  align-items: center;
}
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

//Text decoration
.line_clamp1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.line_clamp2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.line_clamp3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.line_clamp4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

//Scroll
.scroll {
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 20px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $bg_dark;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $bg_form;
    border-radius: 100px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $bg_form;
  }
}

.scroll_7 {
  @extend .scroll;

  /* width */
  &::-webkit-scrollbar {
    width: 7px;
  }
}

.scroll_10 {
  @extend .scroll;

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }
}

//Animation
@keyframes movement_in_air {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

//MEDIA QUIRY
@media screen and (max-width: 1350px) {
  .home_blur {
    // display: none;
  }
}
@media screen and (max-width: 680px) {
  .title_l {
    font-size: 30px;
  }

  .title_xl {
    font-size: 40px;
  }
}

@media screen and (max-width: 450px) {
  .title_l {
    font-size: 30px;
  }

  .title_sm {
    font-size: 20px;
  }

  .title_xl {
    font-size: 25px;
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    color: $white;
  }

  .text {
    font-size: 16px;
  }

  .blur_purple {
    width: 144px;
    height: 144px;
    left: -72px;
    top: 276px;
    filter: blur(60px);
  }

  .blur_blue {
    width: 209.35px;
    height: 141.57px;
    left: 215px;
    top: 123.34px;
    filter: blur(60px);
    transform: matrix(0.92, 0.39, 0.39, -0.92, 0, 0);
  }
}
