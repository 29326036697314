.home {
  // position: absolute;
  width: 100%;
  min-height: 100vh;
  padding-top: 160px;
  font-family: "Poppins", sans-serif;

  &-svg{
    position: absolute;
    top: 120px;
    left: 100px;
    opacity: 0.3;
    background: radial-gradient(50% 0% at 44.12% 60.81%, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
  }

  &-button{
    margin-top: 60px;
  }

  .container {
    position: relative;
    top: 0;
    z-index: 10;
  }
  &-subtitle {
    @extend .title_l;
    margin: 120px 0 60px;
    text-align: center;
  }
  &-top {
    display: flex;
    justify-content: space-between;
    gap: 100px;
    &-left {
      flex-grow: 1;
      
      h1 {
        @extend #{".title_xl"};
        // @extend #{".title_xl", ".gradient_text"};
        // display: block;
        display: flex;
        line-height: 120%;
        margin-bottom: 20px;
      }
      p {
        @extend .text_l;
        width: 523px;
        line-height: 130%;
        margin-bottom: 42px;
        color: #c1c2c8;
      }
      &-title{
      font-weight: 700;
      // font-family: 'Inter', sans-serif;

      p {
        @extend #{".title_xl"};
        // display: block;
        // line-height: 120%;
        margin-bottom: 20px;
        color: rgba(255, 255, 255, 0.50);
      }
      }
      
    }
  }

  &-video{
    margin-top: 100px;
    display: flex;
    justify-content: space-around  ;
  }
  &-introcards {
    display: flex;
    justify-content: center;
    gap: 37px;
    margin-top: 120px;
  }
  &-usage {
    display: flex;
    gap: 142px;
    justify-content: center;
    align-items: center;
    &-card {
      flex-grow: 1;
      p {
        font-weight: 400;
        font-size: 20px;
        line-height: 130%;
        color: #b7b8bc;
      }
    }
  }
  &-about {
    width: 100%;
    display: flex;
    gap: 36px;
    flex-wrap: wrap;
    justify-content: center;
  }
  &-pricing {
    width: 100%;
  }
}

@import "introcard.scss";
@import "aboutcard.scss";
@import "pricingcard.scss";
@import "newscard.scss";

//MEDIA QUERY
@media screen and (max-width: 1450px) {
  .home {
    &-top {
      gap: 70px;
      &-left p {
        width: 100%;
      }
      &-right {
        & > div {
          width: 530px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .home {
    &-usage {
      gap: 60px;
    }
  }
}

@media screen and (max-width: 1350px) {
  .home-top-right{
    display: none;
  }
  
}

@media screen and (max-width: 1200px) {
  .home {
    &-top {
      flex-direction: column;
      gap: 44px;
      align-items: center;
      &-video{
        align-items: center;
      }
    }
    &-usage {
      gap: 60px;
      overflow-x: scroll;
      justify-content: flex-start;
      &::-webkit-scrollbar {
        width: 0;
      }
      &-card {
        width: 365px;
        flex-shrink: 0;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .home {
    &-video{
      div{
        height: 450px !important;
      }
    }
    &-top {
      flex-direction: column;
      gap: 44px;
    }

    &-introcards {
      flex-direction: column;
    }

    &-video{
      & > div{
        height: 530px;

      }
    }
  }
}

@media screen and (max-width: 680px) {
  .home {
    padding-top: 83px;
    &-top {
      &-left {
        h1 {
          margin-bottom: 16px;
        }
        p {
          margin-bottom: 38px;
        }
      }
      &-right {
        video {
          //width: 100% !important;
          height: 300px !important;
        }
      }
      // &-video{
      //    div{
      //     width: 90% !important;
      //     height: 300px !important;
      //   }
      // }
    }

    &-introcards {
      margin-top: 80px;
      gap: 60px;
    }

    &-video{
      div{
        //width: 90% !important;
        height: 300px !important;
      }
    }

    &-svg{
      left: 0;
    }
  }
}

@media screen and (max-width: 550px) {
  .home{
    &-video{
      div{
        height: 250px !important;
      }
    }
  }
  
}

@media screen and (max-width: 450px) {
  .home {
    &-video{
      div{
        height: 220px !important;
      }
    }
    &-top {
      &-video{
        width: 200px;
      }
      &-left {
        h1 {
          font-size: 40px;
        }
        p {
          font-size: 16px;
        }
      }
      &-right {
        & > div {
          height: 200px !important;
        }
      }
    }
    &-subtitle {
      margin: 90px 0 40px;
    }
    &-usage {
      gap: 24px;
      align-items: flex-start;
      &-card {
        width: 270px;
        svg {
          height: 220px;
          width: 270px;
        }
        p {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    &-about {
      gap: 60px;
    }

  }
}
