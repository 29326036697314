//Select
.ant-select-open .ant-select-selection-item {
  color: white !important;
}
:where(.css-dev-only-do-not-override-1qvbt7g).ant-select .ant-select-arrow {
  color: white;
}
:where(.css-dev-only-do-not-override-1qvbt7g).ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #fff;
  font-weight: 600;
  background-color: $white_05;
}
.ant-select-selector {
  box-shadow: none !important;
}

:where(.css-dev-only-do-not-override-lh5u98).ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: $white_05 !important;
}

:where(.css-lh5u98).ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: $white_05 !important;
}

.ant-select-item-option-selected {
  background-color: $white_05 !important;
}

.ant-select-dropdown .ant-select-item-option-selected {
  background-color: $white_05 !important;
}

//Pagination
.ant-pagination-item-active {
  background-color: none !important;
  border: none !important;
}

//Modal
.ant-modal {
  &-close {
    top: 32px !important;
    right: 32px !important;
    &-icon {
      color: $white_07;
      &:hover {
        color: $white;
      }
    }
  }
  &-content {
    padding: 0 !important;
    border: 1px solid #6d6873;
    border-radius: 24px !important;
  }
}

//Upload
.ant-upload {
  &-list {
    svg {
      color: $white;
    }
  }
}

//Form
:where(.css-dev-only-do-not-override-15b3ub8).ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form {
  &-item {
    &-label {
      label {
        opacity: 0.6;
      }
    }
  }
}

:where(.css-dev-only-do-not-override-15b3ub8).ant-form-vertical .ant-form-item-label {
  padding-bottom: 2px !important;
}

input {
  outline: 1px !important;
}

@media screen and (max-width: 600px) {
  .ant-modal {
    max-width: 100%;
    &-close {
      top: 16px !important;
      right: 16px !important;
    }
  }
}
