.newscard {
  img {
    border-radius: 24px;
    width: 100%;
    // height: 322px;
    object-fit: cover;
    background-position: center;
  }

  &-date {
    @extend .text_xs;
    color: $text;
    line-height: 17px;
    margin: 16px 0 8px;
  }

  &-title {
    @extend #{".title_sm", ".line_clamp2"};
    line-height: 29px;
    margin-bottom: 12px;
  }

  p {
    @extend #{".text_s", ".line_clamp2"};
    color: $text;
    line-height: 140%;
  }
}

//MEDIA QUERY
@media screen and (max-width: 1450px) {
  .newscard {
    img {
      //height: 280px;
    }
  }
}
