.docum {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 100;
  &-heading {
    @extend .title_l;
    @extend .display_center;
  }

  &-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    grid-gap: 36px;
    z-index: 20;

    &-left {
      //width: 33%;
      width: auto;
      &-photo {
        border-radius: 24px;
      }

      &-date {
        @extend .text_xs;
        padding-top: 16px;
      }

      &-title {
        @extend .title_sm;
        padding: 12px 0 16px 0;
        width: 394px;
      }

      &-text {
        @extend .text_s;
        width: 380px;
      }
    }
  }
}

@media screen and (max-width: 1450px) {
  .docum {
    &-box {
      grid-gap: 10px;
      &-left img {
        width: 350px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .docum {
    &-box {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
      //align-items: center;
      grid-gap: 36px;
    }
  }
}
