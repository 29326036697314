.documcard {
  width: 400px;
  background-color: $bg_dark;
  border: 1px solid $stroke;
  border-radius: 16px;

  &-content {
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 18px;
    // border-bottom: 1px solid $stroke;

    &-icon {
      flex-shrink: 0;
      width: 68px;
      height: 68px;
      background: $gradient_angular;
      border-radius: 12px;
      @extend .display_center;
    }

    &-info {
      flex-shrink: 0;
      width: 245px;
      h3 {
        @extend #{".text", ".line_clamp1"};
        line-height: 100%;
        margin-bottom: 9.6px;
      }
      p {
        font-weight: 400;
        font-size: 13px;
        line-height: 100%;
        color: $text2;
      }
    }

    &-delete {
      svg {
        cursor: pointer;
      }
    }
  }

  &-footer {
    padding: 24px;
    text-align: center;
    cursor: pointer;

    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    color: $white;
  }
}

@media screen and (max-width: 600px) {
  .documcard {
    width: 100%;
    &-content {
      gap: 14px;
      &-info {
        width: calc(100% - 102px);
        h3 {
          white-space: pre-wrap;
        }
      }
      &-icon {
        width: 50px;
        height: 50px;
        svg {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}
