.memberscard {
  margin: 0 auto;
  &-photo {
    border-radius: 24px;
    // width: 100%;
    height: 347px;
    object-fit: cover;
    background-position: center;
  }

  &-title {
    @extend #{".title_sm", ".line_clamp2"};
    line-height: 100%;
    margin: 16px 0 8px;
  }

  p {
    @extend #{".text_s", ".line_clamp2"};
    color: #cfd0d3;
    line-height: 100%;
  }
}

@media screen and (max-width: 600px) {
  .memberscard {
    &-photo {
      width: 100%;
      height: auto;
    }
  }
}
