.projectinfo {
  &-header {
    display: flex;
    justify-content: space-between;
    padding-right: 70px;
    div {
      display: flex;
      gap: 12px;
    }
  }
  &-informs {
    &:first-child {
      margin-bottom: 40px;
    }
    h3 {
      @extend .text_s;
      opacity: 0.5;
      margin-bottom: 4px;
    }
    div {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      p {
        @extend .text_l;
        width: 400px;
        flex-grow: 1;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .projectinfo {
    &-informs {
      div {
        gap: 16px;
        p {
          width: calc(100% - 40px);
        }
      }
    }
  }
}
