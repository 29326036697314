.qualityid {
  width: 826px;
  background-color: $bg_dark;
  border-radius: 24px;

  &-info {
    padding: 40px 40px 67px 32px;
    border-bottom: 1px solid $stroke;
    &-header {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 16px;
      div {
        @extend .display_center;
        width: 80px;
        height: 80px;
        background-color: $bg_form;
        border-radius: 50%;
        svg {
          width: 40px;
          height: 40px;
        }
      }
      p {
        @extend .title;
        font-weight: 500;
      }
    }
    &-description {
      @extend .text_s;
      width: 620px;
      line-height: 140%;
    }
  }

  &-content {
    padding: 30px 40px 24px 32px;

    .ant-input {
      padding: 20px 24px;
    }

    button {
      border-radius: 8px;
      margin-left: auto;
      margin-top: 60px;
    }
  }
}
