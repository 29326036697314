@import '../variables.scss';

.auth {
  height: 100vh;
  padding-top: 150px;
  overflow: hidden;
  position: relative;
  &-content {
    display: flex;
    position: relative;
    top: 0;
    left: 0;
    z-index: 10;
    gap: 80px;

    &-modal{
      display: flex;
      .ant-modal{
        padding: 64px;
        &-content{
          padding: 64px;
        }

        &-header{
          padding: 64px 0;
        }
      }
    }
  }

  &-left {
    flex-grow: 1;
    padding-left: 80px;
    &-content {
      max-width: 503px;
      padding: 35px 37px 45px;
      background-color: $bg_dark;
      border-radius: 24px;
      margin-left: auto;
    }
  }

  &-right {
    width: 50%;

    h3 {
      @extend .title;
      color: $white_06;
      line-height: 100%;
      margin-bottom: 12px;
    }

    h2 {
      @extend .title_l;
      width: 617px;
      line-height: 140%;
      margin-bottom: 58px;
    }

    &-img {
      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }

  &-form {
    &-title {
      @extend .text_l;
      width: 280px;
      color: $white_06;
      line-height: 130%;
      margin-bottom: 40px;

      a {
        color: $white;
        margin-left: 5px;
        white-space: nowrap;
      }
    }

    &-buttons {
      display: flex;
      flex-direction: column;
      gap: 22px;

      button {
        font-weight: 600;
        height: 46px;
      }

      p {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    &-option {
      display: flex;
      align-items: center;
      div {
        flex-grow: 1;
      }
      p {
        padding: 0 8px;
      }
    }
  }
}

.modal{
  padding: 32px;
}

.customform{
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 32px;

}

@media screen and (max-width: 1100px) {
  .auth {
    &-content {
      justify-content: center;
      align-items: center;
    }
    &-left {
      flex-grow: 0;
      padding: 80px 0 0 0;
      &-content {
        width: 500px;
        margin-left: 0;
      }
    }
    &-right {
      display: none;
    }
  }
}

@media screen and (max-width: 600px) {
  .auth {
    padding: 16px;
    &-left {
      padding-top: 120px;
      &-content {
        width: 100%;
        padding: 30px 16px;
      }
    }
  }
}
