.language {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
  }
  //   :where(.css-dev-only-do-not-override-1qvbt7g)
  //     .ant-select-dropdown
  //     .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  //     color: #fff;
  //     font-weight: 600;
  //     background-color: #ba3030 !important;
  //   }
  .ant-select-dropdown .ant-select-item-option-selected {
    color: #fff;
    font-weight: 600;
    background-color: #3030ba !important;
  }

  :where(.css-dev-only-do-not-override-1qvbt7g).ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 46px;
    padding: 2px 11px;
    font-size: 18px;
  }
}
