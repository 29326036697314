// Css variables
$color_primary: var(--color_primary);
$color_white: var(--color_white);
$color_text_primary: var(--color_text_primary);
$color_text_placeholder: var(--color_text_placeholder);
$color_bg_primary: var(--color_bg_primary);
$color_bg_secondary: var(--color_bg_secondary);
$gradient: linear-gradient(88.69deg, #c216ff 0.65%, #1677ff 151.38%);

@mixin flex {
  display: flex;
  align-items: center;
}
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll {
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 20px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #212a37;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #4b5d75;
    border-radius: 100px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #4b5d75;
  }
}
.ant-float-btn-icon {
  width: 40px !important;
  height: 40px !important;
}
.widget {
  display: flex;
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 384px;
  height: 630px;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: #878a99 0px 3px 8px;
  background-color: $color_bg_primary;

  &-opened {
    display: flex;
  }

  &-icon img {
    width: 40px;
    height: 40px;
  }

  &-float {
    box-shadow: #878a99 0px 3px 8px;
  }

  &-header {
    width: 100%;
    padding: 16px;
    background-color: $color_white !important;
    border-radius: 8px 8px 0 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;

    &-user {
      display: flex;
      align-items: center;
      column-gap: 10px;

      &-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      &-name {
        font-size: 16px;
        font-weight: 500;
        color: $color_text_primary;
      }
      &-status {
        font-size: 12px;
        font-weight: 400;
        color: $color_text_primary;
        opacity: 0.7;
      }
    }

    &-right {
      display: flex;
      align-items: center;
      column-gap: 10px;

      .close-icon {
        width: 20px;
        height: 20px;
        cursor: pointer;

        path {
          stroke: $color_text_primary;
        }
      }
    }
  }

  &-darkmode {
    height: 32px;
  }

  &-content {
    padding: 16px 0;
    height: 100%;
    overflow-y: auto;
    @extend .scroll;
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }

  &-message {
    width: calc(100% - 32px);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    &-row {
      display: flex;
      align-items: flex-end;
      column-gap: 8px;
      margin: 8px 0;
    }
    &-box {
      white-space: pre-wrap;
      max-width: 90%;
      border-radius: 8px;
      padding: 6px 12px;
      border: none;
      color: $color_text_primary;
      background-color: $color_bg_secondary;
      font-size: 14px;
      line-height: 24px;
      min-height: 36px;
      box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
    }
    &-user {
      background-color: $color_primary;
      color: #fff;
      margin-left: auto;
    }
    &-loading {
      min-width: 60px;
    }
    &-ref {
      transform: rotatex(90deg) !important;
      height: 1px !important;
      width: 1px !important;
    }
    &-icon {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
      @include flex-center;
      flex-shrink: 0;

      svg {
        width: 20px;
        height: 20px;

        path {
          fill: #c7c4d5;
        }
      }
    }

    &-ai {
      padding-left: 16px;
    }
  }

  &-nodata {
    p {
      display: inline-block;
      padding: 12px 16px;
      border-radius: 16px;

      font-weight: 500;
      font-size: 18px;
      color: #fff;
    }
  }

  &-input {
    width: 100%;
    padding: 16px;
    background-color: $color_white;

    border-radius: 0 0 8px 8px;

    @include flex-center;
    column-gap: 8px;

    .ant-input {
      height: 37px;
      border: none;
      background-color: $color_bg_secondary;
      color: $color_text_primary;
      font-size: 13px;

      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        color: $color_text_placeholder;
      }
    }

    &-button {
      width: 42px !important;
      height: 37px !important;
      background-color: $color_primary !important;
      border: none !important;
      padding: 0 !important;
      @include flex-center;

      svg {
        width: 20px;
        height: 20px;
      }

      &-disabled {
        background-color: $color_bg_secondary !important;
        cursor: not-allowed !important;

        svg {
          cursor: not-allowed !important;
        }
      }
    }
  }
}

/**
   * ==============================================
   * Dot Flashing
   * ==============================================
   */

$dark_bold: $color_bg_secondary;
.dot-flashing {
  position: relative;
  width: 8px;
  height: 8px;
  top: 6px;
  left: 12px;
  border-radius: 5px;
  background-color: $dark_bold;
  color: $dark_bold;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: $dark_bold;
  color: $dark_bold;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: $dark_bold;
  color: $dark_bold;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: $dark_bold;
  }
  50%,
  100% {
    background-color: #45cb85;
  }
}

@media screen and (max-width: 600px) {
  .chat {
    width: 300px;
  }
}
