$cautionYellow: #fec900;
$danger: #da291c;

.custom404 {
  height: 100vh;
  padding: 80px 0 60px;
  background: $bg_main;
  text-transform: uppercase;
  font-family: "Bangers", cursive;
  text-align: center;

  button {
    background: $cautionYellow;
    border: 10px $bg_main solid;
    color: #000;
    width: 200px;
    height: 60px;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
    font-family: "Bangers", cursive;
    transition: all 0.2s ease;
    letter-spacing: 1px;

    position: relative;
    top: -30px;
    &:hover {
      background: $danger;
      color: #fff;
      border: none;
      transition: all 0.2s ease;
      border-radius: 5px;
    }
  }
  p {
    font-weight: 700;
  }
  .caution-tape {
    font-size: 6rem;
    width: 100%;
    border-top: 50px $cautionYellow solid;
    border-bottom: 50px $cautionYellow solid;
    background: $cautionYellow;
    height: 250px;
    line-height: 80px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .problem-text {
    color: $cautionYellow;
    position: relative;
    top: 60px;
    font-size: 3rem;
    letter-spacing: 3px;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 750px) {
  .notfound {
    .caution-tape {
      font-size: 3rem;
      line-height: 65px;
    }
    .problem-text {
      font-size: 2rem;
    }
  }
}
