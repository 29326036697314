.pricingcard {
  flex-grow: 1;
  height: 575px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 28px 24px;
  max-width: 100%;

  background-color: #242B35;
  border-top: 3px solid #664DFF;
  border-bottom: 1px solid #664DFF;
  box-shadow: 0px 4px 20px 0px rgba(102, 77, 255, 0.20);
  border-radius: 12px;
  

  &-type {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $white;
    margin-bottom: 30px;
  }

  &-price {
    @extend .title_xl;
    line-height: 76px;
    margin-bottom: 4px;

    display: flex;
    align-items: flex-end;
    span {
      font-size: 16px;
      line-height: 40px;
      color: #ffffff60;
      font-weight: 400;
    }
  }

  &-description {
    width: 248px;
    margin-bottom: 37px;

    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $text2;
  }

  &-requirement {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 56px;
    span {
      color: #0151ed;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &-include {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;

    @extend .text_s;
  }

  &-main {
    background-color: #242B35;
    background: //linear-gradient(95deg, rgba(#664DFF, .5), rgba(rgb(144, 153, 236), 0) 25%),
                linear-gradient(210deg, rgba(#664DFF, .5), rgba(white, 0) 20%),;

    .pricingcard {
      &-type {
        color: $white;
        margin-bottom: 15px;
      }
      &-price {
        color: $white;
        span {
          color: $text2;
        }
      }
      &-requirement {
        color: rgba(255, 255, 255, 0.8);
        margin-bottom: 71px;
      }
      &-include {
        color: $white;
      }
    }
    &-svg{
      svg{
        position: absolute;
        transform: translateX(-24px);
      }
    }
  }
}

//MEDIA QUERY
@media screen and (max-width: 450px) {
  .pricingcard {
    padding: 20px 16px;
    margin: 0 5px;
    height: 482px;
    &-type {
      margin-bottom: 12px;
    }
    &-price {
      line-height: 100%;
      margin-bottom: 8px;
      font-size: 40px;
      span {
        font-size: 14px;
        line-height: 100%;
      }
    }
    &-requirement {
      margin-bottom: 24px !important;
    }
  }
}
