.custom-slider {
  .slick {
    &-arrow {
      width: 64px;
      height: 64px;
      z-index: 20;
      top: calc(50% - 32px);
    }
    &-prev {
      left: -16px;
    }
    &-next {
      right: -16px;
    }
    &-slide {
      padding: 0 18px;
      &:focus {
        border: none;
        outline: none;
      }
      & > div {
        &:focus {
          border: none;
          outline: none;
        }
      }
    }
  }
}

.slick {
  &-dots {
    li button::before {
      color: $white_09;
    }
    .slick-active button::before {
      color: $white !important;
    }
  }
}
@media screen and (max-width: 450px) {
  .custom-slider {
    .slick {
      &-arrow {
        width: 50px;
        height: 50px;
        z-index: 20;
        top: calc(50% - 32px);
      }
      &-slide {
        padding: 0;
      }
    }
  }
}
