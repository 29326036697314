.about {
  &-buttom {
    &-title {
      @extend .title_l;
      @extend .display_center;
    }
    &-parts {
      display: flex;
      justify-content: space-between;
      gap: 34px;

      &-part {
        display: flex;
        flex-grow: 1;
        padding: 50px 32px;
        border-radius: 24px;
        justify-content: space-between;
        background-color: $bg_dark;
        border: 1px solid #6e6b7a;
        gap: 30px;
        &-img img {
          width: 221px;
          object-fit: cover;
        }

        &-box {
          &-title {
            width: 330px;
            @extend .title;
          }
          &-text {
            padding-top: 16px;
            @extend .text_s;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1450px) {
  .about {
    &-buttom {
      &-parts {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .about-buttom-parts-part {
    width: 100%;
    &-img img {
      width: 120px;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 600px) {
  .about {
    &-buttom {
      &-parts {
        gap: 24px;
        &-part {
          padding: 20px;
          &-img img {
            width: 86px;
          }
          &-box {
            &-title {
              width: 100%;
              font-size: 18px;
            }
            &-text {
              width: 100%;
              font-size: 13px;
              padding-top: 8px;
            }
          }
        }
      }
    }
  }
}
