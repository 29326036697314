.documents {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 54px;
  &-content {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
  }

  &-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;

    position: fixed;
    right: 40px;
    bottom: 43px;

    &-left {
      @extend .display_center;
    }

    &-right {
      display: flex;
      align-items: center;
      gap: 16px;

      .button {
        -webkit-box-shadow: 0px 0px 77px 22px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 77px 22px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 77px 22px rgba(0, 0, 0, 0.75);
        &-light {
          padding: 14.7px 32px;
        }
      }
    }
  }

  &-add {
    padding: 32px;
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 50px;
      margin-bottom: 12px;
      p {
        @extend .title_sm;
      }
      .button {
        height: 50px;
        svg {
          width: 18px;
          height: 18px;
          stroke: $white;
        }
      }
    }
    &-footer {
      margin-top: 24px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      .ant-form-item {
        margin-bottom: 0;
        &-row {
          flex-direction: row-reverse;
          gap: 15px;
        }
        &-label {
          label {
            font-size: 18px;
            &:after {
              display: none;
            }
            svg {
              color: $white;
            }
          }
        }
      }
    }
  }
}

@import "documcard.scss";

@media screen and (max-width: 600px) {
  .documents {
    &-content {
      gap: 24px;
    }
    &-add {
      padding: 16px;
      &-header {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 16px;

        .button {
          height: auto;
          padding: 10px 20px;
        }
      }
      &-footer {
        .button-light {
          padding: 12px 32px;
        }
      }
    }
    &-footer {
      right: 16px;
      bottom: 20px;
    }
  }
}
