.button {
  outline: none;
  border: none;
  border-radius: $border_radius;
  cursor: pointer;

  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: $white;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  padding: 17px 32px;
  background-color: transparent;
  border: 1px solid #77727d;

  transition: $transition;

  &:hover {
    background-color: $secondary_disabled;
    border-color: $white_disable;
  }

  &-light {
    padding: 17px 32px;
    background: $gradient_button;
    border: none;
    &-disabled {
      pointer-events: none;
      background: $gradient_button_disabled;
      color: $white_disable;
    }
    &-bordered {
      padding: 16px 32px;
      border: 1px solid $white;
    }

    &:hover {
      background: $gradient_button_disabled;
      border-color: $white_disable;
    }
  }

  &-dark {
    padding: 15px 24px;
    background: $secondary;
    box-sizing: border-box;
    border: none;
    &-disabled {
      pointer-events: none;
      background: $secondary_disabled;
      border: 1px solid $stroke_disabled;
    }
    &-bordered {
      padding: 14px 24px;
      border: 1px solid $stroke;
    }

    &:hover {
      background-color: $secondary_disabled;
    }
  }

  &-white {
    padding: 17px 32px;
    background: $white;
    color: $black;
    border: none;
    &-disabled {
      pointer-events: none;
      background: $white_disable;
      color: $white_disable;
    }
    &-bordered {
      border: 1px solid $white;
    }
    &:hover {
      background: $white_09;
    }
  }
}

@media screen and (max-width: 680px) {
  .button {
    font-size: 14px;
  }
}
