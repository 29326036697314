.footer {
  padding: 140px 0 70px;
  background-color: $bg_main;

  &-container {
    max-width: 90%;
    margin: 0 auto;
  }

  &-companies {
    margin-bottom: 140px;
    &-image {
      @extend .display_center;
      display: flex !important;
      background-color: $bg_dark;
      border-radius: 8px;
      image:nth-child(2){
        transform: scale(2);
      }
      
      // img{
      //   // filter: invert(1);
      //   filter: grayscale(1.0);
      // }

      &-express242{
        img{
          transform: scale(.45);
        }
      }

      &-chinwakil1{
        img{
          transform: scale(1.7);
        }
      }

      &-workly1{
        img{
          transform: scale(.5);
        }
      }
    }

    &-image{
      img:hover {
        filter: none;
      }
    }
    h3 {
      font-weight: 600;
      font-size: 18px;
      color: $white;
      line-height: 100%;
      margin-bottom: 40px;
      text-align: center;
    }
    img {
      height: 136px;
    }
  }

  &-content {
    background-color: $bg_dark;
    padding: 40px 40px 51px;
    border: 1px solid #664DFF;
    border-radius: 24px;
    &-header {
      text-align: center;
      padding-bottom: 46px;
      //border-bottom: 1px solid $stroke2;
      h2 {
        @extend .title;
        margin-bottom: 16px;
      }
      p {
        @extend .text_s;
        margin-bottom: 24px;
      }
    }

    &-buttons {
      @extend .display_center;
      gap: 16px;
    }

    &-main {
      padding: 34px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      border-bottom: 1px solid $stroke2;
      gap: 250px;
    }

    &-left {
      display: flex;
      gap: 125px;
      //width: 300px;
      h1 {
        @extend .title;
        margin-bottom: 12px;
      }
      p {
        display: block;
        @extend .text;
        color: $text2;
        margin-bottom: 40px;
      }
      ul {
        display: flex;
        //flex-flow: row wrap;
        column-gap: 24px;
        //row-gap: 16px;
        li {
          font-size: 18px;
          line-height: 27px;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }

    &-right {
      //width: 144px;
      display: flex;
      gap: 25px;

      div {
        @extend .display_center;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        //background-color: #49525f;
        cursor: pointer;
      }
    }

    &-personal {
      padding-top: 20px;
      display: flex;
      justify-content: space-between;

      p {
        @extend .text_xs;
        color: $text2;
      }

      div {
        display: flex;
        gap: 20px;
        p {
          cursor: pointer;
        }
      }
    }
  }

  &-icon{
    position: absolute;
    transform: scale(1.7);
  }
}

//MEDIA QUERY

@media screen and (max-width: 1050px) {
  .footer {
    &-content {
      padding: 24px 16px 51px;
      &-main {
        flex-direction: column;
        gap: 40px;
      }

      &-right {
        width: 100%;
        gap: 24px;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .footer{
    &-content{
      &-left{
        flex-wrap: wrap;
        gap: 10px !important;
      }
    }
  }
  
}

@media screen and(max-width: 480px) {
  .footer{
    &-content{
      &-left{
        ul{
          flex-wrap: wrap;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .footer {
    padding: 100px 0 30px;
    &-container {
      max-width: 100%;
    }
    &-companies {
      padding: 0 16px;
      margin-bottom: 100px;
    }
    &-content {
      max-width: 90%;
      margin: 0 auto;
      &-header {
        &-bottom {
          padding-bottom: 40px;
        }
        h2 {
          width: 235px;
          margin: 0 auto 24px;
          font-size: 20px;
          line-height: 100%;
          letter-spacing: 1px;
          font-weight: 600;
        }
        p {
          display: none;
        }
        button {
          padding: 16px 20px;
        }
      }
      &-main {
        padding-bottom: 50px;
      }
      &-left {
        h1 {
          margin-bottom: 8px;
        }
        p {
          margin-bottom: 32px;
        }
      }
      &-personal {
        div p {
          &:nth-child(1),
          &:nth-child(3) {
            display: none;
          }
        }
      }
    }
  }
}
