.introcard {
  flex-grow: 1;
  min-height: 271px;
  border-top: 1px solid #664DFF;
  border-bottom: 1px solid #664DFF;
  //padding: 21px 32px;

  //border: 1px solid #fce4ef40;
  background-color: #242B35;
  border-radius: 16px;
  position: relative;
  box-shadow: 0px 4px 20px 0px rgba(102, 77, 255, 0.20);
  width: 100%;

  &-content {
    margin: 15px;
    h2 {
      @extend .title;
      line-height: 140%;
      margin-bottom: 16px;
    }
    p {
      @extend .text;
      line-height: 140%;
      margin-bottom: 28px;
      color: #c1c2c8
    }
  }

  &-icon {
    display: flex;
    margin-left: 23px;
    width: 86px;
    height: 86px;
    border: 1px solid #fce4ef40;
    border-radius: 100px;

    &-inside{
      margin: 10px;
      position: absolute;
      border: 1px solid #fce4ef40;
      border-radius: 100px;
      width: 66px;
      height: 66px;

      &-svg{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  // &:nth-child(2) {
  //   .introcard-icon {
  //     top: 120px;
  //   }
  // }
  // &:nth-child(3) {
  //   .introcard-icon {
  //     top: 108px;
  //   }
  // }
}

.line{
  border-bottom: 1px solid #fce4ef40;
  transform: translateY(-43px);
  margin-left: 110px;
  &-top{
    border-left: 1px solid #fce4ef40;
    height: 24px;
    margin-left: 66px;
  }
  &-left{
    border-bottom: 1px solid #fce4ef40;
    width: 24px;
    transform: translateY(43px);
  }
}

@media screen and (max-width: 450px) {
  .introcard {
    padding: 16px;
    min-height: 211px;
    h2 {
      line-height: 24px;
      margin-bottom: 12px;
    }

    &-icon {
      top: 81px;
      right: -11px;

      img {
        width: 171px;
        object-fit: cover;
      }
    }
    &:nth-child(2) {
      .introcard-icon {
        top: 97px;
        right: 0;

        img {
          width: 148px;
        }
      }
    }
    &:nth-child(3) {
      .introcard-icon {
        top: 85px;
        right: 0;

        img {
          width: 155px;
        }
      }
    }
  }
}
