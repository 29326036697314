.chat {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &-message {
    width: 768px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    &-box {
      white-space: pre-wrap;
      max-width: 500px;
      border-radius: 8px;
      padding: 6px 12px;
      margin: 8px 0;
      border: none;
      color: #ced4da;
      background-color: #182533;
      font-size: 16px;
      line-height: 24px;
      min-height: 36px;
    }
    &-user {
      background-color: #2b5278;
      color: #fff;
      align-self: flex-end;
    }
    &-ref {
      transform: rotatex(90deg) !important;
      height: 1px !important;
      width: 1px !important;
    }
  }

  &-nodata {
    p {
      @extend .text;
      display: inline-block;
      background-color: $bg_form;
      padding: 12px 16px;
      border-radius: 16px;
    }
  }

  &-input {
    width: 100%;

    position: sticky;
    bottom: 0px;

    display: flex;
    justify-content: center;

    .ant-input-affix-wrapper {
      width: 768px;
      height: 60px;
      margin: 0 auto;
    }
  }
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */

$dark_bold: #ced4da;
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  top: 6px;
  left: 12px;
  border-radius: 5px;
  background-color: $dark_bold;
  color: $dark_bold;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $dark_bold;
  color: $dark_bold;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $dark_bold;
  color: $dark_bold;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: $dark_bold;
  }
  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

@media screen and (max-width: 860px) {
  .chat {
    &-message {
      width: 100%;
      &-box {
        max-width: calc(100% - 30px);
      }
    }
    &-input {
      position: fixed;
      right: 32px;
      bottom: 43px;
      width: calc(100% - 64px);
      .ant-input-affix-wrapper {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .chat {
    &-message {
      &-ref {
        height: 70px !important;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .chat {
    &-input {
      right: 16px;
      bottom: 30px;
      width: calc(100% - 32px);
    }
  }
}
