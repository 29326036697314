@import url("https://fonts.googleapis.com/css2?family=Bangers&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
// @import url('href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "./variables.scss";

@font-face {
  // font-family: 'Inter';
  font-family: "Poppins";
  font-family: "Poppins", sans-serif;
  src: url("../assets/fonts/SF-Pro-Display-Regular.eot");
  src: url("../assets/fonts/SF-Pro-Display-Regular.woff") format("woff"),
    url("../assets/fonts/SF-Pro-Display-Regular.otf") format("opentype");
}

*,
:after,
:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

html,
body {
  font-family:  "Poppins", sans-serif;
  scroll-behavior: smooth;
}

p,
ul,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

.root {
  min-height: 100vh;
  background-color: #0A1220;
  overflow-x: hidden;
  position: relative;
}

.container {
  width: 1394px;
  margin: 0 auto;
}

.input {
  outline: none !important;
}

.half{
  width: calc(50% - 16px);
}

.full{
  width: 100%;
}

@media screen and (max-width: 1450px) {
  .container {
    width: 90%;
  }
}

@media screen and (max-width: 450px) {
  .container {
    width: 100%;
    padding: 0 16px;
  }
}

@import "./notfound/";
@import "./common/";

//Pages
@import "./layout/";
@import "./home/";
@import "./dashboard/";
@import "./documents/";
@import "./quality/";
@import "./chat/";
@import "./auth/";
@import "./about/";
@import "./news/";
@import "./pricing/";

@import "./package/";
